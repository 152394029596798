<div [class.header-wrapper--initial]="isDocsPage()" class="header-wrapper">
  <div [class.container--wide]="isDocsPage()" class="container">
    <div class="header">
      <mg-logo />

      <app-header-nav [isOpen]="isNavOpened()" (closeMenu)="navToggle()" />

      <div class="m-auto"></div>

      <a
        mg-button
        color="secondary"
        [href]="mainHost + '/auth/signin' + utmService.utmQuery() + '&utm_button_target=signin-button-header'"
        data-testid="signin-button-header"
      >
        Войти
      </a>

      @if (isShowRegButton()) {
        <a
          mg-button-accent
          class="desktop ml-16"
          [href]="mainHost + '/auth/signup' + utmService.utmQuery() + '&utm_button_target=signup-button-header'"
          data-testid="signup-button-header"
        >
          Попробовать бесплатно
        </a>
      }

      <button class="mobile ml-16" mg-button-icon color="secondary" (click)="navToggle()">
        <i class="mg-icon-dots"></i>
      </button>
    </div>
  </div>
</div>
